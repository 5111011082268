import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import NavigationBar from "../UserDashboard/NavBar/NavigationBar";
import Footer from "../../../Footer/Footer";

import './mylearnings.css'

const MyLearnings = () => {

    const [ courses, setCourses ] = useState([])
    const [ loading, setloading ] = useState(true)
    const [ error, setError ] = useState(null)

    const navigate = useNavigate();

    const storedUserData = localStorage.getItem('userData')

    const u = JSON.parse(storedUserData)

    useEffect(() => {
        const fetchEnrollments = async () => {
            try{
                const storedUserData = localStorage.getItem('userData');

                if (!storedUserData) {
                    throw new Error('User data not found in local storage');
                }

                const userData = JSON.parse(storedUserData);
                const { api_key, api_secret } = userData;

                const headers = {
                    'Authorization': `token ${api_key}:${api_secret}`
                }

                const response = await fetch('https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.mylearnings.get_enrollments', { headers });

                if (!response.ok) {
                    const errorData = await response.json(); // Try to parse error response
                    const errorMessage = errorData.message || `HTTP error! status: ${response.status}`;
                    throw new Error(errorMessage);
                }

                const data = await response.json();

                if (data.message && data.message.my_courses) {
                    setCourses(data.message.my_courses);
                } else {
                    throw new Error('Invalid response format');
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setloading(false);
            }
        }
        fetchEnrollments();
    }, [])

    const handleCourseClick = (enrollment_id) => {
        sessionStorage.setItem('enrollment_id', enrollment_id);
        navigate(`/dashboard`);
    }

    return(
        <div className="wrapper">
            <NavigationBar />

            <div className="mylearning-wrapper">
                <div className="app-container">
                    {courses.map((c) => (
                        <div key={c.enrollment_id} className="course-card" onClick={() => {handleCourseClick(c.enrollment_id)}}>
                            <div className="course-image">
                                <img src={c.course_image} alt={c.course_name} width={50} height={50}/>
                                <div className="course-action">

                                </div>
                            </div>
                            <div className="course-info">
                                <div style={{display: "flex", flexDirection: 'column'}}>
                                <h3>{c.course_name}</h3><br />
                                <p>{c.educator}</p>
                                </div>
                                <div className="course-progress">
                                    <span className="progress-bar">
                                        {c.progress}
                                    </span>
                                    <span className="progress-status">★★★★★</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                   
            </div>

            <Footer />
        </div>
    )
}

export default MyLearnings;