import React from "react";
import SideBar from "../SideBar/SideBar";


const Attendance = () => {
    return (
        <div>
            <SideBar />
            <h1>Attendance</h1>
        </div>
    )
}

export default Attendance;