
const tryRequire = (path) => {
	try {
		const image = require(`${path}`);
		return image
	} catch (err) {
		return false
	}
};

const Images = {
        
	questionMark: require('./questionMark.png'),
	Screen1_Vector: tryRequire('./Screen1_Vector.png') || require('./questionMark.png'),
	Screen1_Vector_1: tryRequire('./Screen1_Vector_1.png') || require('./questionMark.png'),
	Screen1_Vector_2: tryRequire('./Screen1_Vector_2.png') || require('./questionMark.png'),
	Screen1_image1: tryRequire('./about.png') || require('./questionMark.png'),
	Screen1_Image1: tryRequire('./Screen1_Image1.png') || require('./questionMark.png'),
	Screen1_Image1_1: tryRequire('./Screen1_Image1_1.png') || require('./questionMark.png'),
	Screen1_Image1_2: tryRequire('./Screen1_Image1_2.png') || require('./questionMark.png'),
	Screen1_Image1_3: tryRequire('./Screen1_Image1_3.png') || require('./questionMark.png'),
	Screen1_Image1_4: tryRequire('./Screen1_Image1_4.png') || require('./questionMark.png'),
	Screen1_Image1_5: tryRequire('./Screen1_Image1_5.png') || require('./questionMark.png'),
	Screen1_Image1_6: tryRequire('./Screen1_Image1_6.png') || require('./questionMark.png'),
	Screen1_Image1_7: tryRequire('./Screen1_Image1_7.png') || require('./questionMark.png'),
	Screen1_Image1_8: tryRequire('./Screen1_Image1_8.png') || require('./questionMark.png'),
	Screen1_Image2: tryRequire('./Screen1_Image2.png') || require('./questionMark.png'),
	Screen1__1: tryRequire('./Screen1__1.png') || require('./questionMark.png'),
	logo: tryRequire('./logo.png') || require('./questionMark.png'),
	whatsapp: tryRequire('./icons8-whatsapp-48.png') || require('./questionMark.png'),
	instagram: tryRequire('./icons8-instagram-48.png') || require('./questionMark.png'),
	facebook: tryRequire('./icons8-facebook-48.png') || require('./questionMark.png'),
	linedin: tryRequire('./icons8-linkedin-48.png') || require('./questionMark.png'),
	coursedescription_Image2: tryRequire('./ccc.png') || require('./questionMark.png'),
	full_logo: tryRequire('./wlc-logo-full.png') || require('./questionMark.png'),
	login_image: tryRequire('./Mobile-login-rafiki.png') || require('./questionMark.png'),
	contact: tryRequire('./qqqq.png') || require('./questionMark.png'),
	pro: tryRequire('./pro.png') || require('./questionMark.png'),

}

export default Images