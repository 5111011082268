import React, { useState } from "react";
import NavigationBar from "../UserDashboard/NavBar/NavigationBar";
import ImgAsset from "./../../../../public"

import './contact.css'
import Footer from "../../../Footer/Footer";


const teamMembers = [
    {
        name: "John Doe",
        title: "CTO",
        image: ImgAsset.pro,
        socials: ['LinkedIn', 'Twiiter', 'GitHub'],
    },
    {
        name: "John Doe",
        title: "CTO",
        image: ImgAsset.pro,
        socials: ['LinkedIn', 'Twiiter', 'GitHub'],
    },
    {
        name: "John Doe",
        title: "CTO",
        image: ImgAsset.pro,
        socials: ['LinkedIn', 'Twiiter', 'GitHub'],
    },
    {
        name: "John Doe",
        title: "CTO",
        image: ImgAsset.pro,
        socials: ['LinkedIn', 'Twiiter', 'GitHub'],
    },
]


const ContactUser = () => {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [msg, setMsg] = useState('')

    // const handleChange = (e) => {
    //     setFormData({
    //         ...formData,
    //         [e.target.name]: e.target.value
    //     })
    // }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const response = await fetch('https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.contact_us.contact_us.submit_enquiry', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name, email, msg
                }),
            })

            if (response.ok) {
                console.log('Form submitted successfully');
                alert("Message sent successfully!");
                setName("")
                setEmail("")
                setMsg("")
            } else {
                const errorData = await response.json(); // Get error details from the server
                console.error("Form submission failed:", errorData); // Log specific errors
                alert("Error submitting form. Please try again later."); // Inform the user
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert("Error submitting form. Please try again later."); // User-friendly message
        }
    };

    return(
        <div>
            <NavigationBar />
            <div className="contact-container">
                <div className="hero">
                    <img src={ImgAsset.contact} />
                </div>
                <div className="contact-content">
                     
                    <h1>Our Story</h1>
                    <img src={ImgAsset.contact} className="content-img"/>
                    <div className="contact-story">
                        <p>
                            Wilfreeda's Language Centre is a futuristic language center intending to further grow as a linguistics research center. 
                            We are proud to say that we have more than 100 satisfied students in just one year from our commencement of operations. 
                            Our institution is named after the late Mrs. Wilfreeda Patric who has proved herself as an 
                            efficient English trainer for more than 35 years. Our methodologies are adapted from her simple form of classroom 
                            discourses and are backed up with research oriented results from a research conducted by our founder 
                            Mr. Shinoj P.John. Our methods are flexible and adaptive for the students to actively participate in them 
                            and eventually achieve their language goals. <br /><br />

                            WLC thrives and works towards becoming the ultimate destination for all your language needs. 
                            From cognitive language acquisition programs for school going students to corporate training for 
                            companies and organizations. We would like to hold your hand throughout your transformational journey! 
                        </p>
                    </div>

                    <div className="mission">
                        <h1>Our Vision</h1>
                        <p>
                            At Wilfreeda's Language Centre (WLC), we envision a world where intercultural competence 
                            flourishes through effective and dynamic language programs. Our mission is to foster global 
                            understanding and communication by delivering top-notch language education that empowers 
                            individuals to navigate diverse cultures with ease. As a future linguistic research hub, 
                            we are committed to advancing the field through collaborative research, innovative curriculum 
                            creation, and partnerships with leading academic institutions. By championing these efforts, 
                            WLC aims to be a beacon of excellence in language education and intercultural competence. 
                        </p>
                    </div>
                    <div className="mission">
                        <h1>Our Mission</h1>
                        <p>
                            The mission of Wilfreeda's Language Centre (WLC) is to revolutionize language and communication 
                            training by partnering with leading experts and organizations. Through the use of cutting-edge cognitive 
                            methodologies, we aim to deliver exceptional educational experiences that empower individuals and 
                            organizations to excel in their communication abilities. 
                        </p>
                    </div>

                    {/* <div className="team-section">
                        <h2>Our Team</h2>
                        <div className="team-members">
                            {teamMembers.map((member, index) => (
                            <div className="team-member" key={index}>
                                <img src={member.image} alt={member.name} className="member-image" />
                                <div className="member-info">
                                <h3>{member.name}</h3>
                                <span>{member.title}</span>
                                <p>{member.socials.join(', ')}</p>
                                </div>
                                <p className="member-title">{member.title}</p> Title on the right
                            </div>
                            ))}
                        </div>
                    </div> */}

                    <div className="contact-form">
                        <h1>Get in Touch</h1>
                        <div className="contact-form-container">
                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    
                                    <input type="text"
                                    id="name"
                                    placeholder="name"
                                    value={name}
                                    onChange={e => setName(e.target.value)}
                                    required />
                                </div>
                                <div className="form-group">
                                  
                                    <input type="email"
                                    id="email"
                                    placeholder="email"
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    required />
                                </div>
                                <div className="form-group">
                                   
                                    <textarea
                                    id="msg"
                                    placeholder="message"
                                    rows={4}
                                    value={msg}
                                    onChange={e => setMsg(e.target.value)}
                                    required />
                                </div>
                                <button type="submit">Send Message</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
        
    );
}


export default ContactUser;