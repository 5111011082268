import React from "react";

import './ComingSoon.css'

const ComingSoon = () => {
    return (
        <div className="coming-soon-box">
            <span className="coming-soon-text">Coming Soon!</span>
        </div>
    )
}


export default ComingSoon;