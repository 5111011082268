import React, { useEffect, useState } from "react";
import Calendar from 'react-calendar';
import './Calendar.css'; 

const MyCalendar = () => {
    const [value, onChange] = useState(new Date());
  const [startDate] = useState(new Date(2024, 7, 1)); // July 6, 2024
  const [endDate] = useState(new Date(2024, 7, 25));   // July 31, 2024

  const isInRange = (date) => {
    return date >= startDate && date <= endDate;
  };

  useEffect(() => {
    const highlightTiles = document.querySelectorAll('.highlight-range');
    highlightTiles.forEach((tile, index, tiles) => {
        if (index === 0) {
            tile.classList.add('round-left');
        } else if (index === tiles.length -1) {
            tile.classList.add('round-right');
        }
    });
  }, [startDate, endDate]);

  const tileClassName = ({ date, view }) => {
    if (view === 'month') {
      if (date.toDateString() === new Date().toDateString()) {
        return 'highlight-current';
      } else if (isInRange(date)) {
        return 'highlight-range';
      }
    }
  };

  return (
    <Calendar
      onChange={onChange}
      value={value}
      tileClassName={tileClassName}
      calendarType="gregory"
    />
  );
}

export default MyCalendar;