import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './dynamiclist.css';

function DynamicList({ history }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (history && history.length > 0) {
        setItems(history)
    }
  }, []); 

  return (
    <div className='history-container'>
        {/* <div className='header'>
            <span>Previous Sessions</span>
            <Link to='/all-sessions' className='view-all-link'>View All</Link>
        </div> */}
        <ul className='list-container'>
        {items.slice(0, 3).map((item, index) => (
            <li 
                key={index} 
                className='list-item'
            >
                <div className='details-container'>
                    <p className='item-detail title-container'>{item.activity}</p>
                    <p className='item-detail'> {item.class_date} </p>
                    <p className='item-detail'> {item.trainer} </p>
                    <p className='item-detail last-item'> {item.class_status} </p>
                </div>
            </li>
        ))}
        {items.length > 3 && (
            <li key={3} className='list-item faded-item'>
                <div className='details-container'>
                    <p className='item-detail title-container'>{items[3].title}</p>
                    <p className='item-detail'> {items[3].session} </p>
                    <p className='item-detail'> {items[3].date} </p>
                    <p className='item-detail last-item'> {items[3].status} </p>
                </div>
            </li>
        )}
        </ul>
    </div>
  );
}

export default DynamicList;
