import React from "react";
import ImgAsset from "../../../../../public";
import { NavLink, useNavigate } from "react-router-dom";
import './navigationbar.css';
import { GiHamburgerMenu } from "react-icons/gi";
import { ImCross } from "react-icons/im";
import { useState } from 'react';


const NavigationBar = () => {
    
    const [ clicked, setClicked ] = useState(false)

    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('userData');
        navigate('/login')
    }

    const handleClick = () => {
        setClicked(!clicked)
    }

    return (
        <nav className={`nav ${clicked ? 'nav-toggle-active' : ''}`}>
            <a href="/userDashboard"><img className="navLogo" src={ImgAsset.logo} alt="logo" width={65} /></a>
            <ul className={`navBarWrapper ${clicked ? 'toggle-active': ''}`}>
                <li className="navElements">
                    <NavLink to='/userDashboard' className="link" activeClassName="">
                        Home
                    </NavLink>
                </li>

                <li className='navElements'>
                    <NavLink to="/courses" className="link" activeClassName="active">
                        Courses
                    </NavLink>
                </li>

                <li className='navElements'>
                    <NavLink to="/mylearnings" className="link" activeClassName="active">
                        My Learnings
                    </NavLink>
                </li>

                <li className='navElements'>
                    <NavLink to="/blog" className="link" activeClassName="active">
                        Blogs
                    </NavLink>
                </li>

                <li className='navElements'>
                    <NavLink to="/contactUs" className="link" activeClassName="active">
                        Contact Us
                    </NavLink>
                </li>

                <li className='navButton'>
                    <NavLink to="/login" activeClassName="active" onClick={handleLogout}>
                        <button className='register'>Logout</button>
                    </NavLink>
                </li>

            </ul>
            { !clicked ? <GiHamburgerMenu className='navIcon' onClick={handleClick}/> : <ImCross className='navIcon' onClick={handleClick}/> }
        </nav>
    )
}


export default NavigationBar;