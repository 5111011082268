import React, { useState } from 'react';
import ImgAsset from  '../../public';
import { NavLink } from 'react-router-dom';
import './navigation.css';
import { GiHamburgerMenu } from 'react-icons/gi';
import { ImCross } from 'react-icons/im';


// Navbar Component
const Navbar = () => {

  const [ clicked, setClicked ] = useState(false)
  const [ isLoggedIn, setIsLoggedIn ] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  }

  const handleLogin = () => {
    setIsLoggedIn(true);
  }

  const handleLogout = () => {
    setIsLoggedIn(false);
  }


  return (
    <nav className={`nav ${clicked ? 'nav-toggle-active' : ''}`}>

      <a href='/' className='navAnchor'><img className='navLogo' src={ImgAsset.logo} alt='X' width={65}/></a>
      <ul className={`navBarWrapper ${clicked ? 'toggle-active' : ''}`}>

        <li className='navElements'>
          <NavLink to="/" className="link" activeClassName="">
            Home
          </NavLink>
        </li>

        <li className='navElements'>
          <NavLink to="/course" className="link" activeClassName="active">
            Courses
          </NavLink>
        </li>

        <li className='navElements'>
          <NavLink to="/blogs" className="link" activeClassName="active">
            Blogs
          </NavLink>
        </li>

        {/* <li className='navElements'>
          <NavLink to="/faqs" className="link" activeClassName="active">
            FAQs
          </NavLink>
        </li> */}

        <li className='navElements'>
          <NavLink to="/contact" className="link" activeClassName="active">
            Contact Us
          </NavLink>
        </li>

        <li className='navButton'>
          <NavLink to="/login" activeClassName="active">
            <button className='register'>Login</button>
          </NavLink>
        </li>
        
      </ul>
      { !clicked ? <GiHamburgerMenu className='navIcon' onClick={handleClick}/> : <ImCross className='navIcon' onClick={handleClick}/> }

    </nav>
  );
}

export default Navbar;





// // Styled Components for Styling
// const NavbarContainer = styled.nav`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding: 1rem 0rem;
//   background-color: #ffffff; 
//   position: fixed; // Make it sticky
//   top: 0;
//   left: 0;
//   margin-right: 12px;
//   width: 100%;
//   z-index: 1; // Ensure it's above other content
//   transition: box-shadow 0.3s ease-in-out; // Smooth shadow transition

//   /* Add shadow when scrolled */
//   box-shadow: ${({ scrolled }) => (scrolled ? '0 4px 6px -6px rgba(0, 0, 0, 0.1)' : 'none')};
// `;

// const Logo = styled.h1`
//   font-size: 1.5rem;
//   font-weight: bold;
//   margin: 0 0 0 3rem;
// `;

// const NavMenu = styled.ul`
//   list-style: none;
//   margin: 0;
//   padding: 0;
//   display: flex;

//   li {
//     margin-right: 2rem; 
//   }

//   // a {
//   //   text-decoration: none;
//   //   color: #333; // Example link color
//   // }
  
//   .active { 
//     font-weight: bold;
//     color: #1E58A8; // Example active link color
//   }
// `;

// const RegisterButton = styled.button`
//   padding: 0.5rem 2rem;
//   margin-right: 2rem;
//   width: 150px;
//   background-color: rgba(30,88,168,1.00); // Example button color
//   color: white;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
// `;