import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import './AllSessions.css';

const AllSessions = () => {
    
    const [items, setItems] = useState([]);
    
    useEffect(() => {
        setItems([
            {title: 'Writing', session: 'Session 1', date: 'June 26', status: 'Live Session & Assignment Completed'},
            {title: 'Speaking', session: 'Session 2', date: 'June 28', status: 'Live Session & Assignment Completed'},
            {title: 'Listening', session: 'Session 3', date: 'June 30', status: 'Live Session & Assignment Completed'},
            {title: 'Writing', session: 'Session 4', date: 'June 26', status: 'Live Session & Assignment Completed'},
            {title: 'Speaking', session: 'Session 5', date: 'June 28', status: 'Live Session & Assignment Completed'},
            {title: 'Listening', session: 'Session 6', date: 'June 30', status: 'Live Session & Assignment Completed'},
        ]);
    }, []);



    return(
        <>
        <SideBar />

        <div className='all-sessions-container'>
        <div className='sessions-header'>
            <span>Previous Sessions</span>
        </div>
        <ul className='sessions-list'>
        {items.slice(0, 3).map((item, index) => (
            <li 
                key={index} 
                className='session-item'
            >
                <div className='session-details'>
                    <p className='session-info title-container'>{item.title}</p>
                    <p className='session-info'> {item.session} </p>
                    <p className='session-info'> {item.date} </p>
                    <p className='session-info'> {item.status} </p>
                </div>
            </li>
        ))}
        {items.length > 3 && (
            <li key={3} className='session-item faded-session'>
                <div className='session-details'>
                    <p className='session-info title-container'>{items[3].title}</p>
                    <p className='session-info'> {items[3].session} </p>
                    <p className='session-info'> {items[3].date} </p>
                    <p className='session-info'> {items[3].status} </p>
                </div>
            </li>
        )}
        </ul>
    </div>
    </>

    )
}

export default AllSessions