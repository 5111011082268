import React from "react";
import SideBar from "../SideBar/SideBar";
import ComingSoon from "../Dashboard/ComingSoon";
import Avatar from "../Components/Avatar";

const Analytics = () => {
    return (
        <div className="dashboard">
            <SideBar />
            <div className="page-contents">
                <Avatar />
            </div>
            
        </div>
    )
}

export default Analytics;