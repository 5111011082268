import React, { useState } from "react";
import './SideBar.css';
import ImgAsset from '../../../../public';
import { SideBarData } from "./SideBarData";
import { NavLink, useNavigate } from "react-router-dom";
import { MdLogout, MdMenu } from "react-icons/md";

const SideBar = () => {
    const navigate = useNavigate();

    const [isSidebarOpen, setIsSidebarOpen] = useState(false)

    const handleLogout = () => {
        localStorage.removeItem('userData');
        navigate('/login')
    }

    const shouldHighlight = link => {
        if (link === '/dashboard' || link === "/all-sessions") {
            return window.location.pathname === '/dashboard' || window.location.pathname === "/all-sessions"
        } else {
            return window.location.pathname === link
        }
    }

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen)
    }

    return (
        <div className={`sidebar ${isSidebarOpen ? 'open' : 'closed'}`} onClick={toggleSidebar}>
            <div className="sidebar-toggle" onClick={toggleSidebar}> 
                <MdMenu />
            </div>
            {/* {isSidebarOpen && ( */}
            <ul className="sidebarlist">
                <li className='row l'>
                    <NavLink to='/dashboard'>
                        <img className='navLogos' src={ImgAsset.full_logo} alt='X' width={245}/>
                    </NavLink>
                </li>
                {SideBarData.map((val, key) => {
                    return (
                        <li 
                            className='row' 
                            id={shouldHighlight(val.link) ? "active" : ""}
                            key={key} 
                            // onClick={() => {
                            //     window.location.pathname = val.link
                            // }}
                        >
                            <NavLink to={val.link} className='rows'>
                                <div id="icon">{val.icon}</div>
                                <div id="title">{val.title}</div>
                            </NavLink>
                        </li>
                    )
                })}
                {/* Logout Link */}
                <li className='row'>
                    <NavLink to="/login" onClick={handleLogout} className='rows'>
                        <div id="icon"><MdLogout /></div>
                        <div id="title">Logout</div>
                    </NavLink>
                </li>
            </ul>
            {/* )} */}
        </div>
    )
}

export default SideBar;