import React from "react";
import SideBar from "../SideBar/SideBar";

const Document = () => {
    return (
        <div>
            <SideBar />
            <h1>Document</h1>
        </div>
    )
}

export default Document;