import React, { useState, useEffect } from "react";  

import NavigationBar from "../UserDashboard/NavBar/NavigationBar";
import Footer from "../../../Footer/Footer";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import './usercourse.css'


const Courses = () => {
    const [ courses, setCourses ] = useState([])
    const [ userData, setUserData ] = useState(null)
    const location = useLocation()

    const storedUserData = localStorage.getItem('userData')
    const u = JSON.parse(storedUserData)


  
  
  useEffect( () => {


    const fetchCourses = async () => {
      try {
        const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.dashboard.get_featured_courses")
        const data = await response.json()


        const formattedCourses = data.messages.courses.map((course) => ({
          course_id: course.course_id,
          title: course.course_name,
          imgSrc: 'https://wlc-erp.frappe.cloud/' + course.course_image,
          intro: course.description
        }))

        setCourses(formattedCourses)

      } catch (err) {
        console.error("Error fetching courses: ", err)
      }
    } 
    fetchCourses()
  }, [])

  return (
      <div className="wrapper">
          <NavigationBar />
          <div className="user-course-grid">
            <div className="course-grid">
              {courses.map((course) => (
                <Link 
                  key={course.course_id} 
                  to={`/courses/courseDetail/`} className="course-card"  state={ course.course_id }>

                  <p className="course-title">{course.title}</p>
                  <img src={course.imgSrc} alt={course.title} />
                  <div className="course-content">
                    <div className="course-intro">{course.intro}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
          <Footer />
      </div>
    );
}

export default Courses;