import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import NavigationBar from "../../UserDashboard/NavBar/NavigationBar";

import './blogdetails.css'
import Footer from "../../../../Footer/Footer";

const BlogDetailsContainer = styled.div`
    padding: 120px 0% 70px 0%;
`;


const BlogTitle = styled.h1`
    text-align: center;
    font-family: "Geologica", sans-serif
`;

const BlogImage = styled.img`
    display: block;
    margin: 0 auto;
    max-width: 100%;
    // width: 700px;
    height: auto;
    margin-top: 2%;
    /* Other image styles */
`;

const BlogContent = styled.div`
    /* Your content styles */
    padding: 2% 170px;
    font-family: "Geologica", sans-serif;
    font-weight: 200;

    @media screen and (max-width: 480px){
        padding: 2% 20px;
    }
`;



const BlogDetailsUser = () => {

    const { blogID } = useParams();
    const [blog, setBlog] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchBlogDetails = async () => {
            try {
                const response = await fetch('https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.blogs.blogs.get_blog_details',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            blog_id: blogID
                        })
                    }
                )

                if (!response.ok) {
                    const errorData = await response.json();
                    const errorMessage = errorData.messages?.error || 'Something went wrong';
                    throw new Error(errorMessage)
                }

                const data = await response.json();

                console.log(data.messages.status)// Render only if blog is not null
                
                if (data.messages.status === 1) {

                    setBlog(data.messages.blog_details[0]);
                } else {
                    throw new Error('Invalid response format');
                }

                setLoading(false);
            } catch (err) {
                setError(err.messages);
                setLoading(false);
            }
        }

        fetchBlogDetails();
    }, [blogID])

    return (
        <div>
            <NavigationBar />
            {loading ? ( 
                <p>Loading...</p>
            ) : error ? (
                <p>Error: {error}</p>
            ) : blog ? (
                <BlogDetailsContainer>
                    <div className="container-blog">
                    <BlogTitle>{blog.title}</BlogTitle>
                    <BlogImage src={blog.image} alt={blog.title} />
                    <BlogContent dangerouslySetInnerHTML={{ __html: blog.description }} />
                    </div>
                </BlogDetailsContainer>
            ) : (
                <p>Blog not found.</p>
            )}
            <Footer />
        </div>
    )
}

export default BlogDetailsUser;