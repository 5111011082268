import React from "react";
import SideBar from "../SideBar/SideBar";
import "../Dashboard/Dashboard.css"
import 'react-tabs/style/react-tabs.css';
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const MyClass = () => {
    return (
        <div className="dashboard">
            <SideBar />
            <div className="page-contents">
                <h1>Dashboard</h1>
                <Tabs>
                    <TabList>
                        <Tab>Title 1</Tab>
                        <Tab>Title 2</Tab>
                    </TabList>
                    <TabPanel>
                        <h2>contents 1</h2>
                    </TabPanel>
                    <TabPanel>
                        <h2>Contents 2</h2>
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}

export default MyClass;