import React, { useEffect, useState } from 'react';
import './CurrentSession.css'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import MyCalendar from '../MyCalendar';
import DynamicList from './DynamicList';

const CurrentSession = () => {
    // console.log("from current Session: ", enrollmentData.messages.todays_activity[0].activity)

    const enrollment_id = sessionStorage.getItem('enrollment_id');

    const [enrollmentData, setEnrollmentData] = useState(null);
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)


    // if (!enrollmentData || !enrollmentData.messages || !enrollmentData.messages.todays_activity || enrollmentData.messages.todays_activity.length === 0) { // Check if data is still loading
    //     return <div>Loading enrollment data...</div>;  // Or a better loading indicator
    // }

    // const todaysActivity = enrollmentData.messages.todays_activity[0];

    const storedUserData = localStorage.getItem('userData')
    const userData = JSON.parse(storedUserData)
    const { api_key, api_secret } = userData

   
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const response = await fetch('https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.mylearnings.get_enrollment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `token ${api_key}:${api_secret}`
                    },
                    body: JSON.stringify({
                        enrollment_id: enrollment_id
                    }),
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.message || 'Failed to fetch data');
                }

                const data = await response.json();
                setEnrollmentData(data)
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message)
            } finally {
                setLoading(false)
            }
        };

        if (enrollment_id) {
            fetchData();
        }


    }, [enrollment_id, api_key, api_secret])

    if (loading) {
        return <div>Loading enrollment data...</div>;
    }
    if (error) {
        return <div>Error: {error}</div>;
    }
    if (!enrollmentData) {
        return <div>No enrollment data available. contact operations</div>;
    }

    const todaysActivity = enrollmentData.messages.todays_activity[0];


    const sessionContainer = enrollmentData.messages.todays_activity && enrollmentData.messages.todays_activity.length > 0 ? (
        <div className="session-container">
            <div className='card-body'>
                <p>
                    <span className='Title'>Title: </span> <span className='value'>{enrollmentData.messages.todays_activity[0].activity}</span>
                </p>
                <p>
                    <span className='Title'>Instructor: </span><span className='value'>{enrollmentData.messages.todays_activity[0].trainer}</span>
                </p>
                <p>
                    <span className='Title'>Schedule: </span> <span className='value'>{enrollmentData.messages.todays_activity[0].schedule}</span>
                </p>
                <p>
                    <span className='Title'>Duration: </span> <span className='value'>{enrollmentData.messages.todays_activity[0].duration}</span>
                </p>
                <p>
                    <span className='Title'>Status: </span> <span className='value'>{enrollmentData.messages.todays_activity[0].class_status}</span>
                </p>    
            </div>
            <div className='card-footer'>
                <a href={enrollmentData.messages.todays_activity[0].class_link} target="_blank" rel="noopener noreferrer">
                    <button className='goto-class-btn'>Go to Class</button>
                </a>
            </div>
        </div>
    ) : (
        <div className="session-container">
            <div className='card-body'>
                <p>
                    <span className='value'>No Schedule for today</span>
                </p>  
            </div>
        </div>
    );

  return (
    <div>
        <h1 className='today'>Today's Activity</h1>
        <div className='main-container'>
            <div className='container-left'>
                <div className='card-top'>

                    {sessionContainer}


                    <div className='chart-card'>
                        <h2>Progress</h2> <hr />
                        <div className="chart" style={{ width: 200, height: 200 }}>
                            <CircularProgressbar 
                                value={enrollmentData.messages.progress} 
                                text={`${enrollmentData.messages.progress}%`} 
                                styles={buildStyles({
                                    pathTransitionDuration: 0.5, pathColor: 'rgba(10,14,97,1.00)', textColor: 'rgba(10,14,97,1.00)', trailColor: 'whitesmoke'
                                })}
                            />
                        </div>
                    </div>
                </div>

                <div className='container-bottom'>
                    {/* <div className='container-activity'>
                        <div className='column activity'>Activity:</div>
                        <div className='column status'>Status: Incomplete</div>
                        <div className='column open'>
                            <button>Open Assignment</button>
                        </div>
                    </div> */}
                    <div className='history'>
                        <DynamicList history={enrollmentData.messages.past_schedules} />
                    </div>
                </div>
            </div>
            <div className='container-right'>
                <div className='card-right'>
                    <div className='calendar'>
                        <MyCalendar />
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CurrentSession;