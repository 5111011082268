import React, { useState } from "react";
import './login.css'
import { MdArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ImgAsset from './../../../public'

const Login = () => {

    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            const apiEndpoint = 'https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.Auth.auth.sign_in'
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            if (response.ok) {
                const data = await response.json();
                
                if (data.message.status_key === 1) {
                    const userData = {
                        ...data.message,
                        full_name: data.full_name
                    };
                    const essestialData = {
                        api_key: data.message.api_key,
                        api_secret: data.message.api_secret
                    }
                    // console.log(essestialData)
                    localStorage.setItem('userData', JSON.stringify(essestialData))
                    localStorage.setItem('user', JSON.stringify(userData))
                    navigate("/userDashboard", { state: {userData} });
                } else {
                    alert("Incorrect Username or Password")
                }
            } else {
                const errorData = await response.json();
                alert(`Error: ${errorData.message || 'An error occured'}`)
            }
        } catch (error) {
            console.error('Error during login:', error);
            alert("An Error Occured during login")
        }
    }

    const handleClick = () => {
        navigate("/")
    }

    return (
        <>
            <div className="login-container">
                {/* <div className="signup-section"> 
                    <h2>Sign Up</h2>
                    
                </div> */}

                <div className="content blue">
                    {/* Your content for the blue section */}
                    {/* <img src="" /> */}
                    <div><img src={ImgAsset.login_image} alt="Logo" width={800}/></div>
                </div>
                <div className="content">
                    <div className="Login">

                        <div className="breadcrumb" onClick={() => handleClick()}><MdArrowLeft size={30} /> back to home</div>
            
                        <h1>Ready to Explore?</h1>
                        <form onSubmit={handleSubmit}>
                            <input type="text" placeholder="Username" value={username} onChange={ e => setUsername(e.target.value)}/>
                            <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)}/>
                            <a href="/login" style={{textAlign: 'end'}}>Forgot Password</a>
                            <button type="submit">Login</button>
                        </form>
                        <p>Don't have an account? <a href="/signup">Create one</a></p>

                    </div>
                </div>

                
            </div>
        </>
    )
}

export default Login;