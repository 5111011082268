import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ImgAsset from './../../../../public'
import NavigationBar from "../UserDashboard/NavBar/NavigationBar";
import Footer from "../../../Footer/Footer";
import { useNavigate } from 'react-router-dom';

const CourseGrid = styled.div`
  position: relative;
  display: grid;               
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto); 
  gap: 1rem;                  
  left: 8%;
  margin: 5% 0;

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    left: 8%;
    margin: 15% 0;
    gap: 10px;
  }
`;

const CourseCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px; // Adjust for desired card width
//   border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  padding-bottom: 56px;
  padding-top: 55px;

  @media screen and (max-width: 480px) {
    padding-bottom: 5px;
    padding-top: 5px;
  }
`;

const CourseImage = styled.img`
  width: 100%;
  height: 180px; // Adjust for desired image height
  object-fit: cover;
  margin-bottom: 1rem;
  border-radius: 4px;
`;

const CourseContent = styled.div`
  padding: 0rem;
`;

const CourseTitle = styled.p`
  margin-bottom: 1rem;
  align-self: center;
  font-size: 17px;
  color: #2B2F76;
  align-items: center;
  text-align: center;
`;

const CourseIntro = styled.p`
  font-size: 11px;
  line-height: 1.4;
  color: #737476;
  width: 100%;
  text-align: justify;
`;

const BlogPageUser = () => {

    const navigate = useNavigate();
    const [blogs, setBlogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
      const fetchBlogs = async () => {
        try {
          const response = await fetch('https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.blogs.blogs.get_blogs_list');
          if (!response.ok) {
            throw new Error('Failed to fetch blogs');
          }
          const data = await response.json();
          setBlogs(data.messages.blogs);
          setLoading(false);
        }
        catch (err) {
          setError(err.messages);
          setLoading(false);
        }
      }

      fetchBlogs();
    }, [])

    if (loading) {
      return <div>Loading...</div>;
    }

    if (error) {
      return <div>Error: {error.messages}</div>;
    }

    const handleCardClick = (blogID) => {
      navigate(`/blog/${blogID}`)
    }

    return (
        <div className="wrapper">
            <NavigationBar />
            <CourseGrid>
                {blogs.map((blog) => (
                    <CourseCard key={blog.blog_id} onClick={() => handleCardClick(blog.blog_id)}>

                        <CourseTitle>{blog.title}</CourseTitle>
                        <CourseImage src={blog.image} alt={blog.title} />
                        <CourseContent>
                
                            {/* <CourseIntro>{course.intro}</CourseIntro> */}
                        </CourseContent>
                    </CourseCard>
                ))}
            </CourseGrid>
            <Footer />
        </div>
    );
  }

export default BlogPageUser;
