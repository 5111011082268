import React, { useState, useEffect, useRef } from "react"
// import './coursedescription.css'
import ImgAsset from '../../../../public'
import NavigationBar from "../UserDashboard/NavBar/NavigationBar";
import { FaAngleRight, FaStar, FaStarHalfAlt, FaPen } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { LuMoveDown } from "react-icons/lu";
import Footer from "../../../Footer/Footer";


const CourseDetails = () => {

	const location = useLocation()
	const courseFromHomePage = location.state || {}

	const [courseDetail, setCourseDetail] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)

	const timeSlots = [
        "10am - 11am", "11am - 12pm", "2pm - 3pm", "3pm - 4pm", 
        "4pm - 5pm", "5pm - 6pm", "6pm - 7pm", "7pm - 8pm", "8pm - 9pm"
    ];

	const [name, setName] = useState("")
	const [email, setEmail] = useState("")
	const [phone, setPhone] = useState("")
	const [apiMessage, setApiMessage] = useState("")

    const [selectedTime, setSelectedTime] = useState("");
	const [selectedDate, setSelectedDate] = useState("")

	const bookingFormRef = useRef(null)

	const scrollToBookingForm = () => {
		if (bookingFormRef.current) {
			bookingFormRef.current.scrollIntoView({ behavior: 'smooth' })
		}
	}



	useEffect(() => {
		const fetchCourseDetails = async () => {
			try {
				if (!courseFromHomePage) {
					throw new error("Course ID not available")
				}

				const courseID = courseFromHomePage;

				const response = await fetch(`https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.course.get_course_details?course_id=${courseID}`)

				if (!response.ok) {
					throw new Error(`HTTP errro! status: ${response.status}`)
				}

				const data = await response.json()

				// console.log("data: ", data.message)
				setCourseDetail(data.message)

				// console.log("course: ",CourseDetails)
			} catch (err) {
				console.error("Error fetching course details: ", err)
				setError(err.message)
			} finally {
				setLoading(false)
			}
		}
		if (courseFromHomePage) {

			fetchCourseDetails()
		}
	}, [courseFromHomePage])


	const courseName = courseDetail.course_name;
    const skills = courseDetail.skills;
    const description = courseDetail.short_description;
	const long_description = courseDetail.long_description;
    const whyThisCourse = courseDetail.why_this_couse; // Note the typo in the response
    const reasons = courseDetail.reasons;
    const courseItems = courseDetail.course_items;
    const courseImage = courseDetail.course_image;

	const handleSubmit = async (event) => {
		event.preventDefault();

		try{
			const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.demo.demo.create_demo_doc", {
				method: "POST",
				headers: {
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					name,
					email,
					phone,
					time_slot: selectedTime,
					selected_date: selectedDate,
					course: courseFromHomePage
				})
			})

			if (!response.ok) {
				throw new Error(`HTTP error! status: ${response.status}`)
			}

			const data = await response.json()

			if (data.messages && data.messages.message) {
				setApiMessage(data.messages.message)
				alert(data.messages.message)

				setName("")
				setEmail("")
				setPhone("")
				setSelectedTime("")
				setSelectedDate("")
			}
		} catch (error) {
			console.error("Error submitting form: ", error)
			setApiMessage("Error submitting form. Please try again later.")
			alert("Error submitting form. Please try again later.")
		}
	}


	return (
		<>
		<NavigationBar />
		<div className='course-wrapper'>
			<div className='course-container'>
				<div className="left-section">
					{/* <div>
						<span className='all-courses'>All courses</span>
						<FaAngleRight className="left-icon" />
						<span className='current-course'>IELTS</span>
					</div> */}

					<span className='course-details-title'>{courseName}</span>
					{/* <span className='program'>Test Preparation</span> */}

					<div className='course-rating'>
						<FaStar className="star" />
						<FaStar className="star" />
						<FaStar className="star" />
						<FaStar className="star" />
						<FaStarHalfAlt className="star" />
						<span className='rates'>(128 reviews)</span>
					</div>
					<span className='course-details-shorts'>{description}</span>

					<div className='skill-container'>
						{courseDetail.skills && courseDetail.skills.length > 0 ? (
							courseDetail.skills.map((skill, index) => (
								<div className='single-skill-wrapper' key={index}>
									<span className='skill-name'>{skill}</span>
								</div>
							))
						):(
							<span></span>
						)}
					</div>

					<div className='book-button' onClick={scrollToBookingForm}>
						<span className='book-button-text'>Book your free DEMO</span>
					</div>

				</div>

				<div className="right-section">
					<img className='Image2' src = {courseImage} />
				</div>	
			</div>

			<div className='about-layer'>
				<span className='course-about'>About {courseName}</span> <br />
				<span className='about-course'>
					{long_description}
				</span>
			</div>
			
			<div className="cards">
				<div className='details'>
					<span className='detail-title'>Why Should you Learn {courseName}</span> <br />
					{courseDetail.why_this_course && courseDetail.why_this_course.length > 0 ? (
						courseDetail.why_this_course.map((item, index) => (
						<div className='detail-about' key={index}>
							<h3>• {Object.keys(item)[0]}</h3>
							<p>{Object.values(item)[0]}</p>
						</div>
						))
					) : (
						<p>No reasons found for this course.</p>
					)}
				</div>
				
				<div className='details'>
					<span className='detail-title'>Why Choose our {courseName} Program</span> <br />
					{courseDetail.reasons && courseDetail.reasons.length > 0 ? (
						courseDetail.reasons.map((item, index) => (
						<div className='detail-about' key={index}> 
							<h3>• {Object.keys(item)[0]}</h3> 
							<p>{Object.values(item)[0]}</p> 
						</div>
						))
					) : (
						<p>No reasons found for this course.</p>
					)}
				</div>
			</div>

			<div className="bottom-tags">	
				<span className='tag tag4'>Ready to take the next step towards achieving your IELTS goals?</span>
				<span className='tag tag1'>Join us and unlock your potential!</span>
				{/* <span className='tag tag-link'>Click here to take free assessment.</span> */}
				<span className='tag tag3'>Take our free assessment to know your English proficiency</span>
			</div>

			<div className="booking-form" ref={bookingFormRef}>
				<h1>Book Free Demo</h1>
				<form className="book-form" onSubmit={handleSubmit}>
					<input type="text" placeholder="Name" value={name} onChange={e => setName(e.target.value)} required/>
					<input type="text" placeholder="Email" value={email} onChange={e => setEmail(e.target.value)} required/>
					<input type="text" placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} required/>
					<input type='date' placeholder='selected date' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} required/>
					<label htmlFor="time-slot">Preferred Time Slot:</label>
					<select className="time-select" id="time-slot" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} required>
						<option value="">Select a time slot</option>
						{timeSlots.map((slot) => (
							<option key={slot} value={slot}>{slot}</option>
						))}
					</select>
					<button className="popup-button" type="submit">Submit</button>
				</form>
			</div>

			<Footer />
		</div>
		</>
	)
}


export default CourseDetails;