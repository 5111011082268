import React from "react";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineClass, MdOutlineAssessment } from "react-icons/md";
import { CiCalendarDate } from "react-icons/ci";
import { IoDocumentsOutline, IoAnalytics } from "react-icons/io5";

export const SideBarData = [
    {
        title: "Dashboard",
        icon: <LuLayoutDashboard />,
        link: "/dashboard"
    },
    {
        title: "My Learnings",
        icon: <MdOutlineClass />,
        link: "/mylearnings"
    },
    // {
    //     title: "Attendance",
    //     icon: <CiCalendarDate />,
    //     link: "/attendance"
    // },
    {
        title: "Main Home",
        icon: <MdOutlineAssessment />,
        link: "/userDashboard"
    },
    // {
    //     title: "Documents",
    //     icon: <IoDocumentsOutline />,
    //     link: "/documents"
    // },
    // {
    //     title: "Analytics",
    //     icon: <IoAnalytics />,
    //     link: "/analytics"
    // },
    // {
    //     title: "Logout",
    //     icon: <IoAnalytics />,
    //     link: "/logout"
    // },
    
]


// the comments can be activated when the features for each options are developed (for phase II)