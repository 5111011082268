import React, { useEffect, useState } from "react";
import './qualitycard.css'

// const courses = [

//     {
//         title: "Our Approach",
//         imageSrc: ImgAsset.Screen1_Image1,
//         intro: "Wilfreedas Language Centre takes a unique approach to test preparation, focusing on both content knowledge and test-taking strategies. Our goal is to prepare students not just for the specific test, but for academic success in general."
//     },

//     {
//         title: 'Experienced Instructors',
//         imageSrc: ImgAsset.Screen1_Image1_2,
//         intro: "Our instructors are highly qualified and experienced, with a deep understanding of the material and the test itself. They have a proven track record of success, helping students improve their scores and achieve their goals.",
//     },

//     {
//         title: 'Bespoke Cariculum',
//         imageSrc: ImgAsset.Screen1_Image1_1,
//         intro: "We understand that each student is unique, with their own strengths, weaknesses, and learning style. That's why we offer customized programmes tailored to each student's individual needs and goals.",
//     },

    
// ];

function QualityList() {

  const [ qualities, setQualities ] = useState([])

  useEffect ( () => {
    const fetchQuality = async () => {
      try {
        const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.dashboard.get_why_wlc")
        const data = await response.json()

        const formattedQuality = data.messages.whys_wlc.map((quality) => ({
          title: quality.title,
          imgSrc: "https://wlc-erp.frappe.cloud/" + quality.img,
          intro: quality.description
        }))


        setQualities(formattedQuality)

      } catch (error) {
        console.error("Error fetching qualities: ", error)
      }
    }

    fetchQuality()
  }, [])

  return (
    <div className="quality-grid">
      {qualities.map((quality, index) => (
        <div className="quality-card" key={index}>
          <img className="quality-img" src={quality.imgSrc} alt={quality.title} />
          <div className="quality-content">
            <p className="quality-title">{quality.title}</p>
            <p className="quality-intro">{quality.intro}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default QualityList;
