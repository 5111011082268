import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowLeft } from "react-icons/md";
import ImgAsset from './../../../../public'
import './signup.css'

function Signup() {
  const navigate = useNavigate()

  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [birth_date, setBirth_date] = useState('');
  const [gender, setGender] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [mobile_no, setMobile_no] = useState('');

  const [errors, setErrors] = useState({});


  const handleSubmit = async (event) => {

    event.preventDefault();

    const validationErrors = validateForm();
    setErrors(validationErrors)

    console.log(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      const formattedDate = birth_date ? new Date(birth_date).toISOString().split('T')[0] : null; 
      
      try {
        const apiEndpoint = 'https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.Auth.auth.sign_up'
        // const apiFormData = new FormData();

        // for (const key in formData) {
        //   const apiKey = key.replace(/([A-z])/g, "_$1")
        //   apiFormData.append(apiKey, formData[key]);
        // }


        const response = await fetch(apiEndpoint, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({first_name, last_name, gender, birth_date, email, password, mobile_no}),
        });

        if (response.ok) {
          alert("Your account has been created. please login!")
          navigate('/login')
        } else {
          const errorData = await response.json();
          console.error('Signup failed', errorData);
          setErrors({apiError: errorData.message || "an error occured"})
        }
      } catch (error) {
        console.error('Error during signup:', error);
        setErrors({apiError: 'error, please try again'})
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    const errors = {};
    // Basic validation rules (add more as needed)

    if (!first_name) errors.first_name = 'First name is required';
    if (!last_name) errors.last_name = 'Last name is required';
    if (!birth_date) errors.birth_date = 'Date of birth is required';
    if (!gender) errors.gender = 'Gender is required';
    if (!email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }
    if (!password) {
      errors.password = 'Password is required';
    } else if (password.length < 8) {
      errors.password = 'Password should be at least 8 characters long';
    }
    if (!mobile_no) errors.mobile_no = 'Phone number is required';

    return errors;
  };

  const handleClick = () => {
    navigate("/")
  }

  return (
    <>
            <div className="signup-container">
                <div className="content blue">
                    <div><img src={ImgAsset.login_image} alt="Logo" width={800}/></div>
                </div>
                <div className="content">
                  <div className="Signup">

                    <div className="breadcrumb" onClick={() => handleClick()}><MdArrowLeft size={30} /> back to home</div>
        
                    <h1>Ready to Explore?</h1>
                    <form onSubmit={handleSubmit}>
                      <div className='grid-row'>
                        <input type='text' placeholder='First Name' value={first_name} onChange={ e => setFirst_name(e.target.value)} required/>
                        <input type='text' placeholder='Last Name' value={last_name} onChange={ e => setLast_name(e.target.value)} required/>
                      </div>
                      <div className='grid-row'>
                        <input type='date' placeholder='Date of Birth' value={birth_date} onChange={ e => setBirth_date(e.target.value)} required/>
                        <select id="gender" name="gender" value={gender} onChange={ e => setGender(e.target.value)} required>
                          <option value="">Gender</option>
                          <option value="Male">Male</option>
                          <option value="Female">Female</option>
                          <option value="Other">Other</option>
                        </select>
                      </div>
                      <input type='text' placeholder='Phone Number' value={mobile_no} onChange={ e => setMobile_no(e.target.value)} required />
                      <input type='text' placeholder='Email' value={email} onChange={ e => setEmail(e.target.value)} required/>
                      <input type="password" placeholder="Password" value={password} onChange={ e => setPassword(e.target.value)} required />
                      <button type="submit">Sign Up</button>
                    </form>
                    <p>have an account? <a href="/login">Login here</a></p>

                  </div>
                </div>
            </div>
    </>
  );
}
export default Signup;
