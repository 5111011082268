import React, { useEffect, useState } from "react";
import SideBar from "../SideBar/SideBar";
import './Dashboard.css'
import '../react-tabs.css';
import Avatar from "../Components/Avatar";
import CurrentSession from "./CurrentSession/CurrentSession";
import { useLocation } from "react-router-dom";
import ComingSoon from "./ComingSoon";
import { MdMenu } from "react-icons/md";


const Dashboard = () => {

    const [ activeTab, setActiveTab ] = useState(0)
    const location = useLocation()

    const [enrollmentData, setEnrollmentData] = useState(null)

    const storedUserData = localStorage.getItem('userData')

    const userData = JSON.parse(storedUserData)


    const { api_key, api_secret } = userData

    // const enrollment_id = state?.enrollment_id;
    const enrollment_id = sessionStorage.getItem('enrollment_id');

    useEffect( () => {

        if(enrollment_id) {

            const fetchData = async () => {
                try {
                    const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.mylearnings.get_enrollment",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                "Authorization": `token ${api_key}:${api_secret}`
                            },
                            body: JSON.stringify({
                                enrollment_id: enrollment_id
                            }),
                        }
                    );
                    if (!response.ok) {
                        const errorData = await response.json();
                        throw new Error(errorData.message || 'Failed to fetch data');
                    }

                    const data = await response.json();
                    setEnrollmentData(data)
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            }

            if (enrollment_id) {
                fetchData();
            }
        }
        
    }, [enrollment_id, api_key, api_secret])

    
    const handleTabClick = (index) => {
        setActiveTab(index)
    }

    return (
        <div className="dashboard">
            <SideBar />
            <div className="page-contents">
                <Avatar />
                <ul className="tabs">
                    {['Current Session', 'Manage Sessions', 'Recordings'].map((label, index) => (
                    <li
                        key={index}
                        className={index === activeTab ? 'active' : ''}
                        onClick={() => handleTabClick(index)}
                    >
                        {label}
                    </li>
                    ))}
                </ul>

                <div className="tab-content">
                   
                    {activeTab === 0 && <CurrentSession />}
                    {activeTab === 1 && <ComingSoon />}
                    {activeTab === 2 && <ComingSoon />}
                </div>
            </div>
        </div>
    )
}

export default Dashboard;