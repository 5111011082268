import React, { useEffect, useState } from "react";

import Navbar from "../../Navigation/Navigation";
import Footer from "../../Footer/Footer";
import './coursepage.css'
import { Link } from "react-router-dom";



const CoursePage = () => {

  const [ courses, setCourses ] = useState([])
  
  
  useEffect( () => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.course.get_course_list")
        const data = await response.json()


        const formattedCourses = data.messages.courses.map((course) => ({
          course_id: course.course_id,
          title: course.course_name,
          imgSrc: 'https://wlc-erp.frappe.cloud/' + course.course_image,
          intro: course.description
        }))

        setCourses(formattedCourses)
      } catch (err) {
        console.error("Error fetching courses: ", err)
      }
    } 
    fetchCourses()
  }, [])

  return (
      <div className="wrapper">
          <Navbar />
          <div className="course-grid-page">
            {courses.map((course) => (
              <Link 
                key={course.course_id} 
                to={`/course/courseDetails/`} className="course-card"  state={course.course_id}>

                <p className="course-title">{course.title}</p>
                <img src={course.imgSrc} alt={course.title} />
                <div className="course-content">
                  <div className="course-intro">{course.intro}</div>
                </div>
              </Link>
            ))}
          </div>
          <Footer />
      </div>
    );
  }

export default CoursePage;
