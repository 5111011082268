import React, { useEffect, useState } from "react";
import './coursecard.css'

function CourseList() {

  const [ courses, setCourses ] = useState([])

  useEffect( () => {
    const fetchCourses = async () => {
      try {
        const response = await fetch("https://wlc-erp.frappe.cloud/api/method/wlc_lms.wlc_lms.apis.dashboard.get_featured_courses")
        const data = await response.json()

        const formattedCourses = data.messages.courses.map((course) => ({
          title: course.course_name,
          imgSrc: "https://wlc-erp.frappe.cloud/" + course.course_image,
          intro: course.description
        }))

        setCourses(formattedCourses)
      } catch (err) {
        console.error("Error fetching courses:", err)
      }
    }

    fetchCourses();
  }, []);
    return (
      <div className="course-grid">
        {courses.map((course, index) => (
          <div className="course-card" key={index}>

            <p className="course-title">{course.title}</p>
            <img src={course.imgSrc} alt={course.title} />
            <div className="course-content">
              <div className="course-intro">{course.intro}</div>
            </div>
          </div>
        ))}
      </div>
    );
  }

export default CourseList;
