import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Screen1 from '../Components/Home';
import CoursePage from '../Components/Pages/CoursePage/CoursePage';
import BlogPage from '../Components/Pages/Blogs/BlogPage';
import Login from '../Components/Pages/Auth/Login';
import Signup from '../Components/Pages/Auth/Register/Register';
import CourseDetails from '../Components/Pages/CourseDetails/CourseDetailsPage';
import Dashboard from '../Components/Pages/Students/Dashboard/Dashboard';
import MyClass from '../Components/Pages/Students/MyClass/MyClass';
import Attendance from '../Components/Pages/Students/Attendance/Attendance';
import Assignments from '../Components/Pages/Students/Assignments/Assignments';
import Document from '../Components/Pages/Students/Document/Document';
import Analytics from '../Components/Pages/Students/Analytics/Analytics';
import AllSessions from '../Components/Pages/Students/Dashboard/CurrentSession/AllSessions';
import BlogDetails from '../Components/Pages/Blogs/BlogsDetails/BlogsDetails';
import Contact from '../Components/Pages/Contact/Contact';
import UserDashboard from '../Components/Pages/Students/UserDashboard/UserDashboard';
import Courses from '../Components/Pages/Students/Courses/Courses';
import CourseDetail from '../Components/Pages/Students/Courses/CourseDetails';
import MyLearnings from '../Components/Pages/Students/MyLearnings/MyLearnings';
import BlogPageUser from '../Components/Pages/Students/Blogs/BlogPage';
import BlogDetailsUser from '../Components/Pages/Students/Blogs/BlogDetails/BlogDetails';
import ContactUser from '../Components/Pages/Students/Contact/Contact';

// const isAuthenticated = () => {
// 	const userData = localStorage.getItem('userData')
// 	console.log("route:", userData)
// 	return userData != null;
// }

const App = () => {

	const [isAuthenticate, setIsAuthenticate] = useState(false)

	useEffect(() => {
        // Check authentication status on component mount and whenever localStorage changes
        const checkAuth = () => {
            const userData = localStorage.getItem('user'); // Use 'user', as corrected in previous answers
            setIsAuthenticate(userData !== null); // Update state
        };

        checkAuth(); // Check initially

        window.addEventListener('storage', checkAuth); // Listen for localStorage changes

        return () => {
            window.removeEventListener('storage', checkAuth); // Clean up the listener
        };
    }, []);



	return (
		<Router>
			<Routes>
				<Route path="/" element={<Screen1 />} />  
				<Route path="/login" element={<Login />} />  
				<Route path="/signup" element={<Signup />} />
				<Route path="/course" element={<CoursePage />} /> 
				<Route path='/course/courseDetails/' element={<CourseDetails />} />       
				<Route path="/blogs" element={<BlogPage />} /> 
				<Route path="/blogs/:blogID/" element={<BlogDetails />} />
				<Route path="/contact" element={<Contact />} />
				
				<Route path='/userDashboard' element={isAuthenticate ? <UserDashboard /> : <Navigate to="/login" />} />
				<Route path='/courses' element={isAuthenticate ? <Courses /> : <Navigate to="/login" />} />
				<Route path='/courses/courseDetail' element={isAuthenticate ? <CourseDetail /> : <Navigate to="/login" />} />
				<Route path='/dashboard' element={isAuthenticate ? <Dashboard /> : <Navigate to="/login" />} />
				<Route path='/mylearnings' element={isAuthenticate ? <MyLearnings /> : <Navigate to="/login" />} />
				<Route path='/myclass' element={isAuthenticate ? <MyClass /> : <Navigate to="/login" />} />
				<Route path='/attendance' element={isAuthenticate ? <Attendance /> : <Navigate to="/login" />} />
				<Route path='/assessments' element={isAuthenticate ? <Assignments /> : <Navigate to="/login" />} />
				<Route path='/documents' element={isAuthenticate ? <Document /> : <Navigate to="/login" />} />
				<Route path='/analytics' element={isAuthenticate ? <Analytics /> : <Navigate to="/login" />} />
				<Route path='/all-sessions' element={isAuthenticate ? <AllSessions /> : <Navigate to="/login" />} />
				<Route path='/blog' element={isAuthenticate ? <BlogPageUser /> : <Navigate to="/login" />} />
				<Route path='/blog/:blogID/' element={isAuthenticate ? <BlogDetailsUser /> : <Navigate to="/login" />} />
				<Route path='/contactUs' element={isAuthenticate ? <ContactUser /> : <Navigate to="/login" />} />
			</Routes>
		</Router>
	);
}
export default App;
