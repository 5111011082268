import React, { useEffect, useState } from "react"
import './avatar.css'

import { HiOutlineBell } from "react-icons/hi2";
import { FaUserAlt } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";
import { useLocation } from "react-router-dom";

const Avatar = () => {
    const location = useLocation();
    const [fullName, setFullName ] = useState("Hello User");
    const [profilePic, setProfilePic] = useState(null)

    useEffect(() => {
        const storedUserData = localStorage.getItem('user');
        
        if (storedUserData) {
            try{
                const userData = JSON.parse(storedUserData);

                setFullName(userData.full_name)
                setProfilePic(userData.profile_pic)
            } catch (error) {
                console.error("Error parsing user data: ", error)
            }
        }

        
    }, [location])
    return (
        <div className="profile">
            <div className="notification-bell">
                <HiOutlineBell />
            </div>
            <div className="avatar">
                {profilePic ? (
                    <img src={profilePic} alt="profile pic" className="avatar-photo" />
            ) : ( <FaUserAlt className="avatar-icon"/> )}
                <span className="user-name">{fullName}</span>
                <MdArrowDropDown className="avatar-down" />
            </div>
        </div>
    )
}

export default Avatar;